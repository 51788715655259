body {
  margin: 0;
}

.rich a, .rich a:visited, .rich a:hover, .rich a:active, .rich a:link {
  color: #00f;
}

li {
  list-style: none;
}

/*# sourceMappingURL=index.fd71f242.css.map */
