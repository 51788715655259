// TODO: move all this SCSS into MUI style theming. This is also where we'd do stuff like disable ripple for desktop etc
.rich {
  a,
  a:visited,
  a:hover,
  a:active,
  a:link {
    color: blue;
  }
}

li {
  list-style: none;
}
